<template>
   <div class="ms_top_artist">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
                  <div class="ms_heading">
                      <h1>نتائج البحث عن : {{ this.$route.params.search_id }}</h1>
                  </div>
              </div>
              <div class="col-6 col-lg-3 col-md-6  col-sm-4 col-xl-2" v-for="(search, index) in searchData" :key="index">
                  <div class="ms_rcnt_box marger_bottom30" @click="getCategorie(search)">
                      <div class="ms_rcnt_box_img">
                          <img v-lazy="search.img" alt="" class="img-fluid">
                          <div class="ms_main_overlay">
                              <div class="ms_box_overlay"></div>
                                <div class="ms_more_icon">
                                    <img src="../../assets/images/svg/more.svg" alt="">
                                </div>
                                <ul class="more_option">
                                    <li><a href="#"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites</a></li>
                                    <li><a href="#"><span class="opt_icon"><span class="icon icon_queue"></span></span>Add To Queue</a></li>
                                    <li><a href="#"><span class="opt_icon"><span class="icon icon_playlst"></span></span>Add To Playlist</a></li>
                                </ul>
                                <div class="ms_play_icon">
                                    <img src="../../assets/images/svg/play.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="ms_rcnt_box_text pointer">
                            <h3><a @click="getCategorie(search)">{{ search.title_ar }}</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
import { onMounted } from '@vue/runtime-core';
export default {
    name: 'AppSearchs',
    props: {
       search_id: String,
    },
    setup(props) {
        const cookie = useCookie()
        const router = useRouter();
        const route = useRoute();
        const searchData = ref([]);
        const GetSearch = async () => {
            await HTTP.get(`GetSearchContent.php?search=${props.search_id}`).then((res) => {
                searchData.value = res.data.Content;
            })
        }
        onMounted(() => {
            GetSearch();
        });

        const getCategorie = (search) => {
          router.push({ name: "Contents", params: { content_id: search.cat_id, cat_name: search.category } });
      };

        return {
            searchData,
            getCategorie
        };
  },
}
</script>

<style>

</style>